<template>
    <span v-show="show || (!isPremium && showPremiumMarker)">
        <b-icon :id="uniqueId" ref="icon" icon="star-fill" :class="getClasses()" :style="`color: ${markerColor}`" />
        <b-popover
            v-if="popover"
            id="popover"
            :target="uniqueId"
            triggers="hover"
            placement="top"
            :boundary="boundary"
            :container="container"
        >
            <div class="text-center">
                <slot></slot>
                <b-button class="button-text" variant="success" size="sm" @click="goPremium()">Go Premium!</b-button>
            </div>
        </b-popover>
    </span>
</template>

<script>
import { defineComponent } from 'vue'
import uniqueId from 'lodash.uniqueid'
import UserEvent from '../objects/UserEvent'
import { mapGetters, mapState } from 'vuex'
import Common from '../mixins/Common'
import EditPayWall from '../mixins/EditPayWall'

export default defineComponent({
    name: 'PremiumMarker',
    mixins: [Common, EditPayWall],
    props: {
        fade: {
            type: Boolean,
            default: false,
        },
        popover: {
            type: Boolean,
            default: true,
        },
        name: {
            type: String,
            default: '',
        },
        page: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: '',
        },
        show: {
            type: Boolean,
            default: false,
        },
        boundary: {
            type: String,
            default: 'scrollParent',
        },
        container: {
            type: String,
            default: 'body',
        },
        feature: {
            type: String,
            default: undefined,
        },
        entity: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            uniqueId: null,
            premiumMarkerColor: 'var(--primary)',
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            hasPlan: 'user/hasPlan',
            isWorksheet: 'document/isWorksheet',
        }),
        ...mapState(['document', 'cards', 'user']),
        markerColor() {
            return this.color ? this.color : this.premiumMarkerColor
        },
        showPremiumMarker() {
            if (!this.isLoggedIn) {
                return false
            }

            if (!this.isWorksheet) {
                return this.showPremiumWall
            }

            if (this.document.is_published && this.document.user_id !== this.user.user.id) {
                return true
            }

            if (this.canUseFeature) {
                return false
            }

            return !!(this.user.user.trialed || this.cards.cards.length)
        },
        isPremium() {
            return this.hasPlan && ['Gratis', 'Lifetime', 'Premium', 'Free Trial'].includes(this.user.user.subscription_plan)
        },
        canUseFeature() {
            if (!Boolean(this.feature)) return true
            return this.hasFeature(this.feature, this.document, this.entity || this.document.entity_type)
        },
    },
    created() {
        this.uniqueId = uniqueId('premium_marker_')
    },
    methods: {
        getClasses() {
            return [this.fade ? 'fade-50' : '']
        },
        async goPremium() {
            this.$emit('click')

            if (this.page) {
                await this.$store.dispatch('document/setDocument', {
                    entity_type: this.page,
                })
            }

            if (this.isLoggedIn) {
                this.showPremiumPaywall()
            } else {
                await this.$modals.open('logIn')
            }

            await this.$logTrackingEvent(UserEvent.PREMIUM_MARKER_CLICKED, this.feature)
            await this.$logTrackingEvent(UserEvent.GO_PREMIUM, this.premiumMarkerColor)
        },
    },
})
</script>

<style scoped>
.button-text {
    white-space: nowrap;
    text-align: center;
}
</style>
